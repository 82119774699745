<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="1" :disabledAll="route.params.projectId === undefined ? true :false" 
        :disabledSignee="disabledSignee" :disabledSignatory="disabledSignatory" :disabledReview="disabledReview" />

    <div v-if="objLSProj.status === undefined && route.params.projectId !== undefined" class="generalBox border shadow-sm">
        <div class="row">
            <div class="col-12">
                <fa icon="spinner" size="lg" class="me-2" />Loading...
            </div>
        </div>
    </div>

    <div v-else-if="objLSProj.status !== 'WIP' && route.params.projectId !== undefined" class="generalBox border shadow-sm">
        <div class="row">
            <div class="col-5 col-lg-4 mb-2 text-center"><fa icon="triangle-exclamation" style="font-size: 120px"/></div>
            <div class="col-7 col-lg-8 mb-2">
                <div class="fw-bold mb-3" style="font-size: 20px">Hmmm... We're sorry, but you don't have permission to view this page.</div>
                <div>Please email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a> for more information.</div>
                <div>Or you may search <span class="text-primary isLink" @click="$router.push('/project')">folder</span> from the rack again.</div>
            </div>
            <div class="col-12"><hr class="my-3"/></div>
            <div class="col-12 text-center">
                <button class="btn btn-secondary" @click="$router.push({ path: '/project' })">
                    <fa icon="folder" size="lg" class="me-2" />Folder
                </button>
            </div>
        </div>
    </div>

    <div v-if="objLSProj.status === 'WIP' || route.params.projectId === undefined" class="generalBox border shadow-sm">
        <div v-if="route.params.projectId === undefined && totalUpload === 0" class="row">
            <div class="col-12">
                <div class="form-floating">
                    <input type="text" class="form-control" v-model="newProjectName" id="newProjectName" placeholder="Name your folder" @focus="true">
                    <label for="newProjectName">Name your folder</label>
                </div>
            </div>
            
            <div class="col-12 mt-3 mb-3">Create a folder with a single document or multiple documents, then invite signees (the participants) to sign on the respective document with their digital identity verified.</div>
        </div>
        <div v-else class="row">
            <div class="col-12">
                <ProjTitle v-if="project && project.folderName" :projId="project.folderId" :projTitle="project.folderName" :isFavourite="project.isFavourite" :mode="project.mode" :permission="project.permission" :totalSignee="project.jSignee.length" :totalDoc="project.totalDocument" :status="project.status" :shred="project.shred" :privacy="project.privacy" :isEdit="true" @refreshProj="getProject" :key="randKey" />
            </div>
            
            <div class="col-12 py-0"><hr class="mb-3 mt-3 py-0" /></div>
            <div v-if="project && project.jDocument && project.totalDocument > 0" class="col-12">
                <table class="table table-hover table-borderless border-sm mb-0" border="0">
                    <tbody>
                        <tr v-for="(doc, docIdx) in project.jDocument" :key="doc.documentId">
                            <td>
                                <div class="row">
                                    <div class="col-12 col-lg-7">
                                        <DocStatus :status="doc.status" :shred="doc.shred"/>
                                        <span class="isLink me-1" :class="doc.status === 'DELETE' ? 'text-grey' : ''" @click.prevent="router.push('/pdf/view/' + project.folderId + '/' + doc.documentId)">{{ doc.name.replace('.pdf', '') }}</span>
                                        <span v-if="doc && doc.isEncrypted === true && doc.totalOldSignature > 0">
                                            <Popper class="popperDark" arrow hover :content="'This document is encrypted and has ' + doc.totalOldSignature + ' existing signature' + (doc.totalOldSignature > 1 ? 's' : '') + ' found before processing, before upload'">
                                                <fa icon="triangle-exclamation" size="lg" class="text-warning" />
                                            </Popper>
                                        </span>
                                        <span v-else-if="doc && doc.isEncrypted === true">
                                            <Popper class="popperDark" arrow hover :content="'This document is encrypted'">
                                                <fa icon="triangle-exclamation" size="lg" class="text-warning" />
                                            </Popper>
                                        </span>
                                        <span v-else-if="doc && doc.totalOldSignature > 0">
                                            <Popper class="popperDark" arrow hover :content="'This document has ' + doc.totalOldSignature + ' existing signature' + (doc.totalOldSignature > 1 ? 's' : '') + ' found before processing, before upload'">
                                                <fa icon="triangle-exclamation" size="lg" class="text-warning" />
                                            </Popper>
                                        </span>
                                    </div>
                                    <div class="col-6 col-md-4 col-lg-2">
                                        <span v-if="doc && doc.jSignee" class="me-2">
                                            <Popper class="popperDark" arrow hover :content="doc.jSignee.length + ' signee' + (doc.jSignee.length > 1 ? 's' : '')">
                                                <span class="badge bg-grey"><fa icon="user" class="me-1" />{{doc.jSignee.length}}</span>
                                            </Popper>
                                        </span>
                                        <span v-if="doc.remark && doc.remark.length > 0" class="me-2">
                                            <Popper class="popperDark" arrow hover :content="'Public note: ' + doc.remark">
                                                <fa icon="clipboard" size="lg" class="text-brown" />
                                            </Popper>
                                        </span>
                                        <span v-if="doc.note && doc.note.length > 0" class="me-2" style="background-color: beige">
                                            <Popper class="popperDark" arrow hover :content="'Private note: ' + doc.note">
                                                <fa icon="note-sticky" size="lg" class="text-brown" />
                                            </Popper>
                                        </span>
                                    </div>
                                    <div class="col-6 col-md-3 col-lg-1">
                                        {{ func.convSize(doc.size) }}
                                    </div>
                                    <!-- Large screen -->
                                    <div class="col-12 col-md-5 col-lg-2 text-end d-none d-lg-block">
                                        <div class="isTooltipsLabel small fst-italic" :class="doc.shred === 'PENDING' ? '' : 'mouseoverHide'">
                                            <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateCreate, 'text')">
                                                {{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}
                                            </Popper>
                                        </div>
                                        <!-- <div class="mouseoverShow">
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="delDoc(doc.documentId, doc.name, docIdx, doc.totalOldSignature)">
                                                <fa icon="rotate-right" size="lg" />
                                            </a>
                                        </div> -->
                                        <div  class="mouseoverShow">
                                            <span v-if="(isSignOn === true && doc.status === 'SIGNED' || doc.status === 'ALLSIGNED' && doc.shred !== 'PENDING')" class="me-3 bg-warning">
                                                <a href="#">
                                                    <Popper class="popperDark" arrow hover content="Revise document">
                                                        <fa icon="rotate-right" size="lg" />
                                                    </Popper>
                                                </a>
                                            </span>
                                            <span v-if="doc.shred !== 'PENDING'">
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="delDoc(doc.documentId, doc.name, docIdx, doc.totalOldSignature, doc.status)">
                                                    <fa icon="trash" size="lg" />
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Small screen -->
                                    <div class="col-12 col-md-5 col-lg-2 d-lg-none ">
                                        <div class="row">
                                            <div class="col-8">
                                                <span class="isTooltipsLabel small fst-italic me-2">
                                                    <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateCreate, 'text')">
                                                        {{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}
                                                    </Popper>
                                                </span>
                                            </div>
                                            <div  class="col-4 text-end">
                                                <span v-if="(isSignOn === true && doc.status === 'SIGNED' || doc.status === 'ALLSIGNED' && doc.shred !== 'PENDING')" class="me-3 bg-warning">
                                                    <a href="#">
                                                        <Popper class="popperDark" arrow hover content="Revise document">
                                                            <fa icon="rotate-right" size="lg" />
                                                        </Popper>
                                                    </a>
                                                </span>
                                                <span v-if="doc.shred !== 'PENDING'">
                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="delDoc(doc.documentId, doc.name, docIdx, doc.totalOldSignature, doc.status)">
                                                        <fa icon="trash" size="lg" />
                                                    </a>
                                                </span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="project.jDocument && project.jDocument.length !== 0" class="row">
            <div class="col-12">
                <hr v-if="project.documentId && project.totalDocument > 0" class="mb-4 mt-2" />
                <hr v-else class="mb-4"/>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-lg-7 pb-2">
                <div class="row">
                    <div v-if="totalUpload === 0 && project.jDocument && project.jDocument.length === 0" class="col-12">
                        <div class="mb-3">Begin a new signing request by uploading pdf document. You can always review your work before you decide to send out the invitation to the signees.</div>
                    </div>

                    <div class="col-12 mb-4">
                        <DropZone @drop.prevent="droppedFile" @change="selectedFile" />
                    </div>

                    <div v-if="totalUpload > 0" class="col-12">
                        
                        <div v-for="(fi, idx) in dropzoneFileAll" :key="fi" class="row mb-3" :id="'progress' + idx">
                            <div class="col-7 col-md-9 fw-bold" v-if="fi.file.name">{{fi.file.name.replace('.pdf', '')}}</div>
                            <div class="col-5 col-md-3 text-end" v-if="fi.file.name">
                                <span>{{ (fi.file.size/(1024*1024)).toFixed(2) }} MB</span>
                                <span v-if="fi.response.status !== 0"  class="isLink ps-3 pe-1" @click="closeProgress('progress' + idx)">
                                    <Popper class="popperDark" arrow hover content="Close">
                                        <fa icon="times" size="lg" />
                                    </Popper>
                                </span>
                            </div>
                            <div class="col-9 mt-2 mb-3">
                                <div class="progress">
                                    <div v-if="fi.response.status === 1" class="progress-bar progress-bar-striped" :style="{width: fi.progress + '%'}">{{fi.progress}} % </div>
                                    <div v-else class="progress-bar bg-danger progress-bar-striped" :style="{width: fi.progress + '%'}">{{fi.progress}} % </div>
                                </div>
                            </div>
                            <div v-if="fi.response.status !== 0" class="col-3 mt-1 mb-3 text-end">
                                <fa v-if="fi.response.status === 1" icon="check-circle" class="text-primary" size="lg"/>
                                <span v-else>
                                    <fa icon="exclamation-circle" data-bs-toggle="tooltip" title="Upload failed" class="text-danger" size="lg"/>
                                </span>
                            </div>

                            <div v-if="idx+1 < dropzoneFileAll.length" class="col-12"><hr class="mb-0" /></div>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div class="col-lg-5">
                <div class="generalContentBox border" >
                    <div class="fw-bold mb-2">Signing Mode</div>
                    <div class="small text-grey fst-italic mb-3">Simple automation to make your signing process easier</div>
                    <div class="btn-group-vertical" style="width: 100%">
                        <button type="button" class="btn" @click="projectMode = 'SIMPLE'" :class="projectMode === 'SIMPLE' ? 'btn-secondary' : 'btn-outline-secondary'">
                            <table>
                                <tr>
                                    <td class="text-start" width="40px"><fa :icon="['far', 'circle']" size="lg"/></td>
                                    <td class="text-start small">
                                        <div>SIMPLE</div>
                                        <div class="text-grey small">Sign with valid email.</div>
                                    </td>
                                </tr>
                            </table>
                        </button>
                        <button type="button" class="btn" @click="projectMode = 'KYC'" :class="projectMode === 'KYC' ? 'btn-secondary' : 'btn-outline-secondary'">
                            <table>
                                <tr>
                                    <td class="text-start" width="40px"><fa icon="circle-half-stroke" size="lg"/></td>
                                    <td class="text-start small">
                                        <div>KYC</div>
                                        <div class="text-grey small">Require identity verification with option for signing sequence.</div>
                                    </td>
                                </tr>
                            </table>
                        </button>
                        <button type="button" class="btn" @click="projectMode = 'STRICT'" :class="projectMode === 'STRICT' ? 'btn-secondary' : 'btn-outline-secondary'">
                            <table>
                                <tr>
                                    <td class="text-start" width="40px"><fa icon="circle"  size="lg"/></td>
                                    <td class="text-start small">
                                        <div>STRICT</div>
                                        <div class="text-grey small">You can review the signing process manually, request for footage of signing, upload hardcopy of signed documents later.</div>
                                    </td>
                                </tr>
                            </table>
                        </button>
                    </div>
                </div>
                
            </div>
        </div>

        <div v-if="(route.params.projectId === undefined && isUploaded) || (route.params.projectId && !isDocSent)" class="row">
            <div class="col-12"><hr class="my-3"/></div>
        </div>

        <div v-if="(route.params.projectId === undefined && isUploaded) || (route.params.projectId && !isDocSent)" class="row text-center">
            <div class="col-12">
                <button class="btn btn-outline-secondary float-start" data-bs-toggle="modal" data-bs-target="#mdlFolderLog">
                    <fa icon="file" size="lg" class="me-2" />Folder logs
                </button>
                <!-- <button class="btn btn-secondary float-end" @click="$router.push({ path: '/addSignee' })" :class="project.totalDocument === 0 ? 'disabled' : ''"> -->
                <button class="btn btn-secondary float-end" @click="updMode()" :class="project.totalDocument === 0 ? 'disabled' : ''">
                    <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                </button>
            </div>
        </div>
    </div>

    <div
      class="modal fade"
      id="mdlDelDoc"
      tabindex="-1"
      aria-labelledby="DelDoc"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 class="modal-title" id="exampleModalLabel"> Shred document</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                    <div class="mb-2">Confirm to shred '{{delFilename}}'?</div>
                    <div v-if="delOldSign > 0" class="mb-2">This document has {{ delOldSign }} existing signature{{ delOldSign > 1 ? 's' : '' }} found before processing, before upload and it is unrecoverable after shred.</div>
                    <div v-if="delDocStatus === 'DRAFT' || delDocStatus === 'VIEWONLY' || delDocStatus === 'INVITED' || delDocStatus === 'SIGNED'">This document will be shredded immediately and unrecoverable.</div>
                    <div v-if="delDocStatus === 'ALLSIGNED' || delDocStatus === 'REVISE'">This document will be shreded after receving approval from all signees who have signed.</div>
              </div>
              <div class="modal-footer" style="justify-content: space-between;">
                <button type="button" class="btn btn-secondary" @click="proceedDelete" data-bs-dismiss="modal" >
                    <fa icon="trash" class="me-2" />Shred
                </button>
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Cancel
                </button>
              </div>
          </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>   

    <!-- Modal: Folder log --> 
    <div class="modal fade" id="mdlFolderLog">
        <div class="modal-dialog modal-dialog-centered modal-lg text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Folder logs</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="auditLogBox">
                        <div v-if="arrFolderLog && arrFolderLog.length === 0">
                            No record found
                        </div>
                        <div v-if="arrFolderLog && arrFolderLog.length > 0">
                            <table class="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <td width="150px">Date</td>
                                        <td>Description</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="l in arrFolderLog" :key="l.id">
                                        <td>{{ func.convDateTimeFormat(l.date, 'datetime') }}</td>
                                        <td>{{ l.description }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, inject, computed, onMounted, watch } from 'vue'
import Alert from '@/components/Alert.vue'
import TopNavigation from '@/components/TopNavigation.vue'
import DropZone from '@/components/DropZone.vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import DocStatus from '@/components/DocStatus.vue'
import ProjTitle from '@/components/ProjTitle.vue'

export default {
    name: 'UploadDoc',
    components: {
        Alert, TopNavigation, DropZone, Tooltip, Popper, DocStatus, /* ProjPrivacy, */ ProjTitle
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const func = funcs

        const isSignOn = ref(false) // detect if from signon Entity

        const uploadPercentage = ref(0)
        const dropzoneFileAll = ref([])
        const num = ref(-1)
        const isUploaded = ref(false) // at least 1 file uploaded successfully
        const totalUpload = ref(0)    // total files has been uploaded
        const isDocSent = ref(false)  // true - as long as 1 of docs not DRAFT 
        
        const project = ref([])
        const projectId = ref('{folderId}')
        const newProjectName = ref('')
        const projectMode = ref('SIMPLE')
        const projectName = ref(null)
        const totalProjectSignees = ref(0)

        const classAll = ref('btn btn-sm btn-secondary')
        const classRestrict = ref('btn btn-sm btn-outline-secondary')

        const disabledSignee = ref(false)
        const disabledSignatory = ref(true)
        const disabledReview = ref(true)

        const delDocId = ref(null)
        const delDocStatus = ref(null)
        const delIndex = ref(null)
        const delFilename = ref(null)
        const delOldSign = ref(0)

        const randKey = ref(1)
        const objLSProj = ref({})

        const arrFolderLog = ref(null)


        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const closeProgress = (id) => {
            num.value = num.value - 1
            const div = document.getElementById(id)
            if (div) {
                div.style.display = 'none'
            }
        }

        const openPopper = () => {
            console.info('popper open!')
        }

        const droppedFile = async (e) => {
            const files = ref([])
            files.value = e.dataTransfer.files

            for (var i = 0; i < files.value.length; i++) {
                num.value = dropzoneFileAll.value.length
                isUploaded.value = true

                dropzoneFileAll.value[num.value] = {
                    file: files.value[i],
                    progress: 0,
                    response: { status: 0}
                }

                totalUpload.value = dropzoneFileAll.value.length

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        uploadPercentage.value = 0
                        uploadPercentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        dropzoneFileAll.value[num.value].progress = uploadPercentage.value 
                    }
                }

                let formData = new FormData()
                formData.append('folderName', newProjectName.value)
                formData.append('file', files.value[i])

                // use await send one by one, return individual progress
                // not use await send parallel, return general progress for all files
                await axios.post( '/signon/upload/' + projectId.value, formData, config)
                    .then((res) => {

                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            if (route.params.projectId === undefined && newProjectName.value === '' && i === 0) {
                                newProjectName.value = files.value[i].name
                            }

                            // console.info('- - - - DROPPED pre', projectId.value, res.data.data.folderId)
                            getProject(res.data.data.folderId)

                            // let pname = project.value.total === undefined || project.value.total === 0 ? files.value[0].name : project.value.folderName 
                            let pname = project.value.folderName !== undefined ? project.value.folderName : files.value[0].name  
                            let pstatus = project.value.status === undefined ? 'WIP' : project.value.status

                            let objLSProj = { id: res.data.data.folderId, name: pname, status: pstatus}
                            localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj))

                            projectId.value = res.data.data.folderId
                            
                            setTimeout(() => setPermission(), 1000)
                            dropzoneFileAll.value[num.value].response = res.data

                        } else {
                            func.addLog('upload', 'droppedFile', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                if (res.data.message === 'invalid_format') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Sorry, only PDF file is accepted.'
                                    })
                                
                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })
                                }
                            }

                        }
                        
                    })
                    .catch((error) => {
                        func.addLog('upload', 'droppedFile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            } // for
        }
    

        const selectedFile = async (e) => {
            const files = ref({})
            files.value = document.querySelector('.dropzoneFile').files

            for (var i = 0; i < files.value.length; i++) 
            {
                num.value = dropzoneFileAll.value.length
                isUploaded.value = true

                dropzoneFileAll.value.push({
                    file: files.value[i],
                    progress: 0,
                    response: { status: 0}
                })

                totalUpload.value = dropzoneFileAll.value.length
                
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: 'include',
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        uploadPercentage.value = 0
                        uploadPercentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        dropzoneFileAll.value[num.value].progress = uploadPercentage.value 
                    }
                }

                let formData = new FormData()
                formData.append('folderName', newProjectName.value)
                formData.append('file', files.value[i])

                // use await send one by one, return individual progress
                // not use await send parallel, return general progress for all files
                // axios.defaults.headers.Cookie = 'yntest=1230000;'
                await axios.post( '/signon/upload/' + projectId.value, formData, config)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        // must get again or rerturn value in object with double quote
                        projectId.value = res.data.data.folderId 
                      
                        // console.info('selectedFile newProjectName - 1', newProjectName.value)
                        if (route.params.projectId === undefined && newProjectName.value === '' && i === 0) {
                            newProjectName.value = files.value[i].name
                        }
                       
                        getProject(res.data.data.folderId) 
                        
                        // let pname = project.value.folderName === undefined ? newProjectName.value : project.value.folderName 
                        let pname = project.value.total === undefined || project.value.total === 0 ? files.value[0].name : project.value.folderName 
                        let pstatus = project.value.status === undefined ? 'WIP' : project.value.status

                        let objLSProj = { id: res.data.data.folderId, name: pname, status: pstatus}
	                    localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj))

                        project.value.total ++
                        setTimeout(() => setPermission(), 1000)

                        dropzoneFileAll.value[num.value].response = res.data

                    } else {
                        func.addLog('upload', 'selectedFile', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'invalid_format') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Sorry, only PDF file is accepted.'
                                })
                            
                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }

                })
                .catch((error) => {
                    func.addLog('upload', 'selectedFile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                     
                })
            } // for
        }

        const delDoc = (documentId, fileName, idx, totalOldSignature, docStatus) => {
            delOldSign.value = 0
            delDocId.value = documentId
            delDocStatus.value = docStatus
            delIndex.value = idx
            delFilename.value = fileName.replace('.pdf', '')
            delOldSign.value = totalOldSignature

        }

        const proceedDelete = () => {
            delOldSign.value = 0
            axios.delete('/signon/' + projectId.value + '/' + delDocId.value)
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        
                        dropzoneFileAll.value.splice(delIndex.value, 1)
                        totalUpload.value = totalUpload.value - 1

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document: " + delFilename.value + " has been deleted successfully"
                        })
                        setTimeout(() => getProject(projectId.value), 1000)
                        
                    } else {
                        func.addLog('upload', 'proceedDelete', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('upload', 'proceedDelete - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const updMode = () => {
            console.info('mode', projectMode.value)
            const p = {
                mode: projectMode.value,
            }

            axios.put( '/signon/' + projectId.value, p)
            .then((res) => {

                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    router.push('/addSignee')
                    /* setTimeout(() => {
                        router.push('/addSignee')
                    }, 1000) */

                } else {
                    func.addLog('upload', 'updMode', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        if (res.data.message === 'user_not_verified') {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'User must be verified for KYC and STRICT folder.'
                            })
                        
                        } else {

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }
                }
            })
            .catch((error) => {
                func.addLog('upload', 'updMode - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const updProject = () => {
            let proceed = true
            let p = {}

            if (route.params.projectId === undefined) {
                p = {
                   folderName: newProjectName.value, 
                   mode: projectMode.value,
                }

                if (newProjectName.value === null || newProjectName.value === '') {
                    proceed = false
                } 

            } else {
                p = {
                    mode: projectMode.value,
                }
            }
            console.info('updProject', p)
            if (proceed) {
                setTimeout(() => {
                    axios.put( '/signon/' + projectId.value, p)
                        .then((res) => {

                            if (res.data.status === 1001) {
                                router.push({ path: '/auth/login', query: {e: 'ns'} })

                            } else if (res.data.status === 1) {
                                let pname = route.params.projectId === undefined ? newProjectName.value : project.value.folderName
                                let pstatus = project.value.status === undefined ? 'WIP' : project.value.status
                                let objLSProj = { id: projectId.value, name: pname, status: pstatus}
                                localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj))

                                setTimeout(() => {
                                    router.push('/addSignee')
                                }, 1000)

                            } else {
                                func.addLog('upload', 'updProject', res)

                                if (res && res.data !== null && res.data !== undefined) {
                                    if (res.data.message === 'user_not_verified') {
                                        alert.value.push({
                                            class: "danger",
                                            title: "ERROR",
                                            message: 'User must be verified for KYC and STRICT folder.'
                                        })
                                    
                                    } else {

                                        alert.value.push({
                                            class: "danger",
                                            title: "ERROR",
                                            message: func.getError(res.data)
                                        })
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            func.addLog('upload', 'updProject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                        })
                }, 500)

            } else {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Please enter folder name.'
                })

            }
        }

        const getProject = async (id) => {
            const p = {
                orderBy: [
                    {
                        field: 'jAuditLog',
                        order: 'desc'
                    }
                ]
            }
            await axios.post( '/signon/' + id, p)
                .then((res) => {
                
                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        // console.info('getproj', res.data.data)
                        randKey.value = randKey.value + 1
                        project.value = res.data.data
                        projectMode.value = project.value.mode
                        totalProjectSignees.value = res.data.data.jSignee.length
                        arrFolderLog.value = res.data.data.jAuditLog

                        setPermission()
                        chkIfDocSent()

                        // Failed to get project value in onmounted 
                        objLSProj.value = { id: id, name: project.value.folderName, status: project.value.status}
                        localStorage.setItem('project', funcs.encodeJsonToStrSimple(objLSProj.value))

                    } else {
                        func.addLog('upload', 'getProject', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    disabledSignatory.value = true
                    disabledReview.value = true

                    func.addLog('upload', 'getProject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const chkIfDocSent = () =>  {
            if (project.value.total > 0) {
                project.value.jDocument.forEach((item, idx) => {
                   
                    if (item.status !== 'DRAFT') {
                        isDocSent.value = true
                    }
                })
            }
        }

        const setPermission = () => {
            if (project.value.totalDocument && project.value.totalDocument > 0) {
                disabledSignee.value = false

                if (totalProjectSignees.value > 0) {
                    disabledSignatory.value = false
                    disabledReview.value = false

                } else {
                    disabledSignatory.value = true
                    disabledReview.value = true

                }

            } else {
                disabledSignee.value = true
                disabledSignatory.value = true
                disabledReview.value = true
                
            }
        }

        onMounted( async () => {

            // initial tooltip - button
            Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))

            // initial tooltip - span
            Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))

                // $('[data-toggle="popover"]').popover()
                // document.querySelectorAll('span[data-bs-toggle="popper"]')

            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            let pid = ''
            if (route.params.projectId === '{folderId}') {
                pid = ''

            } else if (route.params.projectId !== null && route.params.projectId !== 'null' && route.params.projectId !== undefined) {
                pid = route.params.projectId

            } else if (func.isEmptyObject(localStorage.getItem('project')) === false) {
                objLSProj.value = func.decodeStrToJsonSimple(localStorage.getItem('project'))
                pid = objLSProj.value.id

            }

            if (pid === '') {
                disabledSignee.value = true
                disabledSignatory.value = true
                disabledReview.value = true

            } else {
                // Project exists
                projectId.value = pid
                getProject(projectId.value)
                
            }

        })

        return {
            route, alert, closeAlert, store, func, router, 
            uploadPercentage, dropzoneFileAll, isUploaded, totalUpload, num, isDocSent, 
            classRestrict, classAll,
            project, projectId, newProjectName, projectMode, projectName, 
            droppedFile, selectedFile, delDoc, updProject,
            disabledSignee, disabledSignatory, disabledReview, 
            randKey, getProject, objLSProj, delDocId, delDocStatus, delIndex, delFilename, delOldSign, proceedDelete,
            openPopper, closeProgress, arrFolderLog, isSignOn, updMode
        }
    }
}
</script>
// manually dropzone + axios

// localhost https certificate:
// note: localhost own cert not working - shown valid certificate but error: ERR_CERT_CMMON_NAME_INVALID
// solution 1/2 - create cert using mkcert, install choco via shellpower
// Note: mkcert not working in Firefox
// https://web.dev/how-to-use-local-https/
// https://www.groovypost.com/howto/edit-hosts-file-windows-10/
// solution 2/2 - add vue.config.js same level as package.json
// set port: 443, ssl key and cert

/* PENDING:
updProject - axios shd accept signeeMode
delDoc - error to delete in axios 
*/


<style>
body {
    overflow: scroll;
}

#auditLogBox {
    max-height: 300px;
    overflow: scroll;
}

.table-hover > tbody > tr .mouseoverShow {
    visibility: hidden;
    height: auto;
    display: none;
}

.table-hover > tbody > tr .mouseoverHide {
    visibility: visible;
    height: auto;
    display: block;
}

.table-hover > tbody > tr:hover .mouseoverShow {
    visibility: visible;
    height: auto;
    display: block;
}

.table-hover > tbody > tr:hover .mouseoverHide {
    visibility: hidden;
    height: auto;
    display: none;
}
</style>